@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
}

.marge {
  margin: 24px 0px;
}
.flex {
  display: flex;
  justify-content: center;

  flex-direction: column;
}

.ref_img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  width: 700px; /* Adjust the width as needed */
  height: 100%; /* Adjust the height as needed */
  object-fit: contain;
  display: block;
  margin: auto;
}
