.react-datepicker-wrapper {
    display: block;
    width: 100%;
}
.react-datepicker__input-container input {
    padding: 14px 14px;
    margin-bottom: 32px;
    background: white;
    border: none;
    width: 100%;
    border-radius: 10px;
}